export const loginRoutes = [
  "/register",
  "/Register",
  "/login",
  "/Login",
  "/changes",
  "/Changes",
  "/recoverpassword",
  "/RecoverPassword",
  "/recoverPassword",
  "/recoverupdate",
  "/recoverUpdate",
  "/RecoverUpdate",
  "/directory",
  "/Directory",
  "/sendmoney",
  "/SendMoney",
  "/recharge",
  "/Recharge",
  "/profile",
  "/Profile",
  "/movements",
  "/Movements",
  "/accadm",
  "/accAdm",
  "/AccAdm",
  "/admindashboard",
  "/AdminDashboard",
  "/adminDashboard",
  "/users",
  "/Users",
  "/usersr",
  "/usersR",
  "/UsersR",
  "/userse",
  "/usersE",
  "/UsersE",
  "/usersv",
  "/usersV",
  "/UsersV",
  "/currencyprice",
  "/currencyPrice",
  "/CurrencyPrice",
  "/banks",
  "/Banks",
  "/relation",
  "/Relation",
  "/termsandconditions",
  "/termsAndConditions",
  "/TermsAndConditions",
  "/privacy",
  "/Privacy",
  "/Homeusa",
  "/homeusa",
  "/HomeUsa",
];

export const banksByCountry = {
  Venezuela: [
    "0102 - Banco de Venezuela, S.A. Banco Universal",
    "0104 - Banco Venezolano de Crédito, S.A. Banco Universal",
    "0105 - Banco Mercantil C.A., Banco Universal",
    "0108 - Banco Provincial, S.A. Banco Universal",
    "0114 - Banco del Caribe C.A., Banco Universal",
    "0115 - Banco Exterior C.A., Banco Universal",
    "0128 - Banco Caroní C.A., Banco Universal",
    "0134 - Banesco Banco Universal, C.A.",
    "0137 - Banco Sofitasa Banco Universal, C.A.",
    "0138 - Banco Plaza, Banco universal",
    "0146 - Banco de la Gente Emprendedora C.A.",
    "0151 - Banco Fondo Común, C.A Banco Universal",
    "0156 - 100% Banco, Banco Comercial, C.A",
    "0157 - DelSur, Banco Universal C.A.",
    "0163 - Banco del Tesoro C.A., Banco Universal",
    "0166 - Banco Agrícola de Venezuela C.A., Banco Universal",
    "0168 - Bancrecer S.A., Banco Microfinanciero",
    "0169 - Mi Banco, Banco Microfinanciero, C.A.",
    "0171 - Banco Activo C.A., Banco Universal",
    "0172 - Bancamiga Banco Universal, C.A.",
    "0173 - Banco Internacional de Desarrollo C.A., Banco Universal",
    "0174 - Banplus Banco Universal, C.A.",
    "0175 - Banco Bicentenario del Pueblo, Banco Universal C.A.",
    "0177 - Banco de la Fuerza Armada Nacional Bolivariana, B.U.",
    "0178 - Banco Digital, Banco Microfinanciero",
    "0191 - Banco Nacional de Crédito C.A., Banco Universal",
    "0601 - Instituto Municipal de Crédito Popular"
  ],
  Argentina: [
    "Banco de la Nación Argentina",
    "Banco de la Provincia de Buenos Aires",
    "Banco de la Ciudad de Buenos Aires",
    "Banco Galicia",
    "Banco Santander Río",
    "Banco BBVA Argentina",
    "Banco Macro",
    "Banco Patagonia",
    "Banco Supervielle",
    "Banco Itaú Argentina",
    "Banco Comafi",
    "Banco Hipotecario",
    "Banco Credicoop",
    "Banco Industrial"
  ],
  // Agrega más países y sus bancos aquí
  Colombia: [
    "Banco de Bogotá",
    "Banco de Occidente",
    "Banco Popular",
    "Bancolombia",
    "Banco Davivienda",
    "Banco AV Villas",
    "Banco Caja Social",
    "Banco GNB Sudameris",
    "Banco Pichincha",
    "Bancoomeva",
    "Banco Falabella"
  ],
  Chile: [
    "Banco de Chile",
    "Banco Internacional",
    "Banco Itaú",
    "Banco Santander",
    "Banco Security",
    "BancoEstado",
    "BBVA",
    "BCI",
    "Corpbanca",
    "Scotiabank"
  ],
  Ecuador: [
    "Banco del Pacífico",
    "Banco Pichincha",
    "Banco de Guayaquil",
    "Banco Internacional",
    "Produbanco",
    "Banco Bolivariano",
    "Banco del Austro",
    "Banco Solidario"
  ],
  Panama: [
    "Banco Nacional de Panamá",
    "Caja de Ahorros",
    "Banco General",
    "Banistmo",
    "BAC Credomatic",
    "Global Bank",
    "Multibank",
    "Scotiabank"
  ],
  Mexico: [
    "BBVA Bancomer",
    "Banamex",
    "Santander",
    "Banorte",
    "HSBC",
    "Scotiabank",
    "Inbursa",
    "Banco Azteca"
  ],
  Brasil: [
    "Banco do Brasil",
    "Caixa Econômica Federal",
    "Banco Bradesco",
    "Banco Itaú",
    "Banco Santander",
    "Banco Safra",
    "Banco BTG Pactual",
    "Banco Votorantim"
  ],
  Peru: [
    "Banco de Crédito del Perú",
    "BBVA Continental",
    "Interbank",
    "Scotiabank",
    "Banco Pichincha",
    "Banco Falabella",
    "Banco Ripley",
    "Banco Azteca"
  ],
  Uruguay: [
    "Banco de la República Oriental del Uruguay",
    "Banco Itaú Uruguay",
    "Banco Santander Uruguay",
    "BROU",
    "Scotiabank Uruguay",
    "HSBC Uruguay",
    "Citibank Uruguay"
  ],
  Paraguay: [
    "Banco Nacional de Fomento",
    "Banco Itaú Paraguay",
    "Banco Continental",
    "Banco Regional",
    "Banco Familiar",
    "Banco Sudameris Paraguay",
    "Banco GNB Paraguay"
  ],
  Bolivia: [
    "Banco de Crédito de Bolivia",
    "Banco Nacional de Bolivia",
    "Banco Mercantil Santa Cruz",
    "Banco BISA",
    "Banco Ganadero",
    "Banco FIE",
    "BancoSol",
    "Banco Económico"
  ],
  "Estados Unidos": [
    "Bank of America",
  ],
};